import React from "react"
import Layout from "../components/layout"

function Kontakt() {
  return (
    <Layout>
      <h1 className="font-bold text-2xl mt-12 mb-4">Kontakt info</h1>

      <p>
        Jølster Adventure held til på Jølster i Sundfjord kommune i Vestland
        (gamle Sogn og Fjordane), og består av Olav Sægrov (
        <a
          className="text-green-600 hover:text-green-800"
          href="www.skifantomet.com"
        >
          Skifantomet
        </a>
        ) og Carl Nyborg-Christensen Du kan kontakte oss på:
        <ul className="ml-12 mt-6 gap-4 flex flex-col">
          <li>
            <a
              className="text-green-600 hover:text-green-800 flex gap-4"
              href="mailto:post@jolsteradventure.no"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-green-600 hover:text-green-800"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                />
              </svg>
              post@jolsteradventure.no
            </a>
          </li>
          <li>
            <a className="text-green-600 hover:text-green-800 flex gap-4"
              href="https://www.facebook.com/jolsteradventure">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                width="24" height="24"
                fill="currentColor"
                class="bi bi-facebook"
                viewBox="0 0 16 16"
              >
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
              </svg>{" "}
              Facebook
            </a>
          </li>
          <li>
            <a className="text-green-600 hover:text-green-800 flex gap-4"
              href="https://www.instagram.com/jolsteradventure">
              {" "}
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width="24" height="24"
                viewBox="0 0 24 24"
                className="h-6 w-6"
                class="bi bi-instagram"
                fill="currentColor"
              >    
              <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"></path></svg>
              Instagram
            </a>
          </li>
        </ul>
      </p>

      <div className="grid grid-cols-2 gap-32 mt-16">
        <div>
          <h2 className="text-lg text-red-800 font-semibold border-b-2 border-red-800 mb-4">Olav</h2>
          <p className="flex gap-2 hover:underline">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>{" "}
            <a href="tel:41365955">413 65 955</a>{" "}
          </p>
          <p>
            <a
              className="hover:underline"
              href="https://www.instagram.com/skifantometsverden/"
            >
              <span className="text-xl font-bold">@</span>skifantometsverden
            </a>
          </p>
        </div>
        <div>
          <h2 className="text-lg text-blue-800 font-semibold border-b-2 border-blue-800 mb-4">Carl</h2>
          <p className="flex gap-2 hover:underline">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>{" "}
            <a href="tel:95201004">952 01 004</a>
          </p>
          <p>
            <a
              className="hover:underline"
              href="https://www.instagram.com/carlnybo/"
            >
              <span className="text-xl font-bold">@</span>carlnybo
            </a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Kontakt
